<template>
  <div v-if="!isLoading">
    <div class="box box-default">
      <div class="box-header">
        <div class="flex justify-between">
          <h3 class="box-title">Customer KYC/AML Overview</h3>
          <div class="flex align-items-center">
            <span v-if="searching" class="text-danger text-bold mr-8"
              style="display: inline-block; padding-top: 5px;">{{ searching }}
            </span>
            <input v-model="keyword" aria-label="Search" autofocus="autofocus" ref="searchInput" class="form-control"
              placeholder="Search..." type="text" @keyup="searchKYC($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <va-tabs :is-updated="counter" :padding="'padding: 10px 0 0'" @selectedTab="selectedTab">
          <!-- PENDING KYC -->
          <div id="kyc" slot="content" :data-title="'KYC (' + pendingKYC + ')'" class="active">
            <div v-if="loader !== 'kyc'">
              <k-y-c-listing :data="paginatedKYC" :listingType="this.tab" :meta="meta" :sortKey="sortKey" :order="order"
                @updatePage="updatePage" @updateTitle="updateTitle"></k-y-c-listing>
            </div>
            <va-loader v-if="loader === 'kyc'"></va-loader>
          </div>
          <!-- Unverified KYC -->
          <div id="unverified" slot="content" data-title="KYC Unverified">
            <div v-if="loader !== 'unverified'">
              <k-y-c-listing :data="data.unverifiedKYC" :listingType="this.tab" :meta="meta" :sortKey="sortKey"
                :order="order" @updatePage="updatePage"></k-y-c-listing>
            </div>
            <va-loader v-if="loader === 'unverified'"></va-loader>
          </div>
          <!-- DISABLED KYC -->
          <div id="disabled" slot="content" data-title="KYC Disabled">
            <div v-if="loader !== 'disabled'">
              <k-y-c-listing :data="data.disabledKYC" :listingType="this.tab" :meta="meta" @updatePage="updatePage"
                :sortKey="sortKey" :order="order" @updateTitle="updateTitle"></k-y-c-listing>
            </div>
            <va-loader v-if="loader === 'disabled'"></va-loader>
          </div>
          <!-- KYC Cyclic -->
          <div id="cyclic" slot="content" data-title="KYC Cyclic">
            <div v-if="loader !== 'cyclic'">
              <k-y-c-listing :data="data.cyclicKYC" :listingType="this.tab" :meta="meta" :sortKey="sortKey" :order="order"
                @updatePage="updatePage"></k-y-c-listing>
            </div>
            <va-loader v-if="loader === 'cyclic'"></va-loader>
          </div>
          <!-- All -->
          <div id="all" slot="content" data-title="KYC All">
            <div v-if="loader !== 'all'">
              <k-y-c-listing :data="data.all" :listingType="this.tab" :meta="meta" :sortKey="sortKey" :order="order"
                @updatePage="updatePage"></k-y-c-listing>
            </div>
            <va-loader v-if="loader === 'all'"></va-loader>
          </div>
        </va-tabs>
      </div>
    </div>
    <!-- Update Logs Modal -->
    <logs-modal></logs-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VaTabs from "@/components/VATabs.vue";
import VaLoader from "@/components/partials/Loader.vue";
import KYCListing from "@/components/KYCListing.vue";
import { STOP_LOADING } from "@/store/keys";
import { formatDataList, payoutSchemas, saveNotification } from "@/lib/utils";
import LogsModal from "@/components/merchant-detail/modals/LogsModal";

export default {
  name: "KYC",
  components: {
    LogsModal,
    VaTabs,
    VaLoader,
    KYCListing,
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_ADMIN_API_URL,
      tab: "kyc",
      loader: "",
      keyword: "",
      data: {
        pendingKYC: [],
        unverifiedKYC: [],
        disabledKYC: [],
        cyclicKYC: [],
        all: [],
      },
      expandedRowIndex: 0,
      payoutSchemas: payoutSchemas,
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      counter: 0,
      timer: null,
      searching: "",
      sortKey: "RegisteredOn",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "jwtToken",
    ]),
    pendingKYC() {
      return this.data.pendingKYC.length;
    },
    paginatedKYC() {
      if (this.data.pendingKYC.length > 0) {
        return this.data.pendingKYC.slice(
          this.meta.offset * this.meta.limit,
          this.meta.page * this.meta.limit,
        );
      }
      return this.data.pendingKYC;
    },
  },
  methods: {
    isNull(val) {
      return val && val !== "" && val !== null ? val : "NA";
    },
    resetMeta() {
      this.keyword = "";
      this.meta = {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      };
    },
    getPendingKYC(page = 1, keyword = "", isLoader = true) {
      this.loader = "kyc";
      this.$http.get(`${this.apiBaseUrl}/?Call=KYC`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.data.pendingKYC = response.data.data;
          if (this.data.pendingKYC.length > 0) {
            this.data.pendingKYC = this.formatDataList(this.data.pendingKYC);
            this.meta.total = this.data.pendingKYC.length;
          }
          this.counter++;
        }
      }).catch(error => {
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
      });
    },
    getDisabledKYC(page = 1, keyword = "", isLoader = true) {
      if (isLoader) {
        this.loader = "disabled";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=KYCDisabled`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.loader = "";
        this.searching = "";
        if (response.status === 200) {
          this.data.disabledKYC = response.data.data.merchants;
          if (this.data.disabledKYC.length > 0) {
            this.data.disabledKYC = this.formatDataList(this.data.disabledKYC);
          }
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.loader = "";
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getCyclicKYC(page = 1, keyword = "", isLoader = true) {
      if (isLoader) {
        this.loader = "cyclic";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=KYCCyclic`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.loader = "";
        this.searching = "";
        if (response.status === 200) {
          this.data.cyclicKYC = response.data.data.merchants;
          if (this.data.cyclicKYC.length > 0) {
            this.data.cyclicKYC = this.formatDataList(this.data.cyclicKYC);
          }
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.loader = "";
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getUnverifiedKYC(page = 1, keyword = "", isLoader = true) {
      if (isLoader) {
        this.loader = "unverified";
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=KYCUnverified`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.loader = "";
        this.searching = "";
        if (response.status === 200) {
          this.data.unverifiedKYC = response.data.data.merchants;
          if (this.data.unverifiedKYC.length > 0) {
            this.data.unverifiedKYC = this.formatDataList(this.data.unverifiedKYC);
          }
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.loader = "";
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    searchKYC(e) {
      if (e.key !== "/" && e.key !== "Control") {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.searchKYCUser(1, true);
        }, 500);
      }
    },
    searchKYCUser(page = 1, isSearching, id = null) {
      this.tab = "all";
      if (isSearching) {
        this.searching = "Searching...";
      }
      this.loader = "all";
      $(".nav-tabs a[href=\"#" + this.tab + "\"]").tab("show");
      this.$http.get(`${this.apiBaseUrl}/?Call=KYCAll`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword,
          id,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.searching = "";
        this.loader = "";
        if (response.status === 200) {
          this.data.all = response.data.data.merchants;
          if (this.data.all.length > 0) {
            this.data.all = this.formatDataList(this.data.all);
            $(".nav-tabs a[href=\"#" + this.tab + "\"]").tab("show");
          }
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.searching = "";
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    updateTitle(record) {
      if (record) {
        this.data.pendingKYC.splice(0, 0, record);
      }
      this.counter++;
    },
    formatDataList(data) {
      data = formatDataList(data);
      data = data.map(record => {
        return {
          ...record,
          KYCLogs: this.generateKYCLogs(record),
        };
      });
      return data;
    },
    updatePage(page, key) {
      if (key) {
        if (key !== this.sortKey) {
          this.order = "desc";
        } else {
          this.order = this.order === "asc" ? "desc" : "asc";
        }
        this.sortKey = key;
      }
      if (this.tab === "kyc") {
        this.$nextTick(() => {
          this.meta = Object.assign({}, this.meta, {
            page: page,
            offset: page > 0 ? page - 1 : 0,
          });
        });
      } else if (this.tab === "disabled") {
        this.getDisabledKYC(page);
      } else if (this.tab === "cyclic") {
        this.getCyclicKYC(page);
      } else if (this.tab === "unverified") {
        this.getUnverifiedKYC(page);
      } else if (this.tab === "all") {
        this.searchKYCUser(page);
      }
    },
    formatStatus(status) {
      const formattedStatus = status ? "Verified" : "Unverified";
      const lblClass = status ? "label-success" : "label-danger";
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    selectedTab(id) {
      this.tab = id;
      this.resetMeta();
      this.expandedRowIndex = 0;
      if (id === "kyc") {
        this.getPendingKYC();
      } else if (id === "disabled") {
        this.getDisabledKYC();
      } else if (id === "cyclic") {
        this.getCyclicKYC();
      } else if (id === "unverified") {
        this.getUnverifiedKYC();
      } else if (id === "all") {
        this.searchKYCUser();
      }
    },
    getPayoutSchemaType(payoutSchema) {
      if (payoutSchema === "Keep with Cointopay") {
        return "";
      }
      const banks = ["Pay out EUR Bank", "Pay out USD Bank", "Pay out GBP Bank"];
      if (banks.indexOf(payoutSchema) !== -1) {
        return "bank";
      } else if (payoutSchema === "Payout Paypal") {
        return "paypal";
      } else if (payoutSchema === "Payout Payeer") {
        return "payeer";
      } else if (payoutSchema === "Pay out to bank address book default") {
        return "address-book-bank";
      } else if (payoutSchema === "Pay out to crypto address book default") {
        return "address-book-crypto";
      }
    },
    generateKYCLogs(record) {
      // eslint-disable-next-line quotes
      let logs = '##KYC LOG HISTORY##\n' +
        this.tz().format("YYYY-MM-DD HH:mm:ss") +
        "\n\nSubmitted: " + (record.Verified ? 1 : 0) +
        "\nVerified: " + (record.FinalVerification ? 1 : 0) +
        "\nAccount Disabled: " + (record.Disabled ? 1 : 0) +
        "\nAccount Active: " + (record.Active ? 1 : 0) +
        "\nConfirmedEmail: " + (record.ConfirmedEmail ? 1 : 0) +
        "\n2FA ON: " + (record.Google2FAEnabled ? 1 : 0) +
        "\nEmail: " + record.Email +
        "\nPayeer/Paypal: " + this.isNull(record.PayoutTo) +
        "\nRegisteredOn: " + record.RegisteredOn +
        "\nLastLoginOn: " + this.isNull(record.LastLoginOn) +
        "\nAccountUpdatedOn: " + this.isNull(record.LastUpdatedOn) +
        "\n\nAccount: " + record.AccountType.toUpperCase();
      // Business
      if (record.AccountType.toLowerCase() === "business") {
        logs += "\n\nBusiness Name: " + this.isNull(record.PayoutBankName) +
          "\nBusiness Address: " + this.isNull(record.PayoutBankAddress) +
          "\nBusiness PostalCode: " + this.isNull(record.PayoutBankPostalCode) +
          "\nBusiness City: " + this.isNull(record.PayoutBankCity) +
          "\nBusiness State: " + this.isNull(record.PayoutBankState) +
          "\nBusiness Country: " + this.isNull(record.PayoutBankCountry);
      }
      // Personal
      logs += "\n\nBenificiary FullName: " + this.isNull(record.PayoutFullName) +
        "\nBeneficiary First Name: " + this.isNull(record.PayoutFirstName) +
        "\nBeneficiary Last Name: " + this.isNull(record.PayoutLastName) +
        "\nBeneficiary BirthDate: " + this.isNull(record.PayoutBirthDate) +
        "\nBeneficiary PEP (Exposed): " + (record.PEP ? 1 : 0) +
        "\nBenificiary Address: " + this.isNull(record.PayoutAddress) +
        "\nBenificiary PostalCode: " + this.isNull(record.PayoutPostalCode) +
        "\nBenificiary City: " + this.isNull(record.PayoutCity) +
        "\nBenificiary State: " + this.isNull(record.PayoutState) +
        "\nBenificiary Country: " + this.isNull(record.PayoutCountry);
      // Payout Information
      const payoutSchema = this.getPayoutSchemaType(record.PayoutSchema);
      if (payoutSchema !== "") {
        logs += "\n\nPayout Information:" +
          "\nPayout schema: " + record.PayoutSchema;
        if (payoutSchema === "bank") {
          logs += "\nName Bank account: " + this.isNull(record.PayoutName) +
            "\nIBAN/Account number: " + this.isNull(record.PayoutIBAN) +
            "\nBIC/Swift/Routing code: " + this.isNull(record.PayoutBIC);
        } else if (payoutSchema === "payeer" || payoutSchema === "paypal") {
          logs += "\nPayal/Payeer: " + this.isNull(record.PayoutTo);
        } else if (payoutSchema === "address-book-bank") {
          if (record.AddressBookBank !== "NA" && record.AddressBookBank) {
            logs += "\nCurrency: " + record.AddressBookBank.currency +
              "\nAccount holder name: " + record.AddressBookBank.accountHolderName;
            if (record.AddressBookBank.accountType) {
              logs += "\nAccount type: " + record.AddressBookBank.accountType.toLowerCase();
            }
            if (record.AddressBookBank.IBAN) {
              logs += "\nIBAN: " + record.AddressBookBank.IBAN;
            }
            if (record.AddressBookBank.accountNumber) {
              logs += "\nAccount number: " + record.AddressBookBank.accountNumber;
            }
            if (record.AddressBookBank.taxId) {
              logs += "\nTax Id: CUIL / CUIT: " + record.AddressBookBank.taxId;
            }
            if (record.AddressBookBank.bsbCode) {
              logs += "\nBSB code: " + record.AddressBookBank.bsbCode;
            }
            if (record.AddressBookBank.bankCode) {
              logs += "\nBank code: " + record.AddressBookBank.bankCode;
            }
            if (record.AddressBookBank.branchCode) {
              logs += "\nBranch code: " + record.AddressBookBank.branchCode;
            }
            if (record.AddressBookBank.cpf) {
              logs += "\nRecipient tax ID/CPF: " + record.AddressBookBank.cpf;
            }
            if (record.AddressBookBank.phoneNumber) {
              logs += "\nPhone number: " + record.AddressBookBank.phoneNumber;
            }
            if (record.AddressBookBank.institutionNumber) {
              logs += "\nInstitution number: " + record.AddressBookBank.institutionNumber;
            }
            if (record.AddressBookBank.transitCode) {
              logs += "\nTransit number: " + record.AddressBookBank.transitCode;
            }
            if (record.AddressBookBank.rut) {
              logs += "\nBeneficiary's RUT number (Rol Único Tributario): " + record.AddressBookBank.rut;
            }
            if (record.AddressBookBank.cardNumber) {
              logs += "\nUnionPay card number: " + record.AddressBookBank.cardNumber;
            }
            if (record.AddressBookBank.sortCode) {
              logs += "\nSort code: " + record.AddressBookBank.sortCode;
            }
            if (record.AddressBookBank.ifscCode) {
              logs += "\nIFSC code: " + record.AddressBookBank.ifscCode;
            }
            if (record.AddressBookBank.clabe) {
              logs += "\nRecipient CLABE number: " + record.AddressBookBank.clabe;
            }
            if (record.AddressBookBank.swiftCode) {
              logs += "\nSwift code: " + record.AddressBookBank.swiftCode;
            }
            if (record.AddressBookBank.idDocumentType) {
              logs += "\nID document type: " + record.AddressBookBank.idDocumentType;
            }
            if (record.AddressBookBank.idDocumentNumber) {
              logs += "\nIdentification number: " + record.AddressBookBank.idDocumentNumber;
            }
            if (record.AddressBookBank.russiaRegion) {
              logs += "\nRegion: " + record.AddressBookBank.russiaRegion;
            }
            if (record.AddressBookBank.abartn) {
              logs += "\nRouting number: " + record.AddressBookBank.abartn;
            }
            if (record.AddressBookBank.firstLine) {
              logs += "\nAddress: " + record.AddressBookBank.firstLine;
            }
            if (record.AddressBookBank.city) {
              logs += "\nCity: " + record.AddressBookBank.city;
            }
            if (record.AddressBookBank.state) {
              logs += "\nState: " + record.AddressBookBank.state;
            }
            if (record.AddressBookBank.postCode) {
              logs += "\nPostal Code: " + record.AddressBookBank.postCode;
            }
            if (record.AddressBookBank.country) {
              logs += "\nCountry: " + record.AddressBookBank.country;
            }
          }
        } else if (payoutSchema === "address-book-crypto" && record.AddressBookCrypto) {
          if (record.AddressBookCrypto !== "NA") {
            logs += "\nCrypto Currency: " + record.AddressBookCrypto.altCoinID +
              "\nAddress: " + record.AddressBookCrypto.address +
              "\nCustomer Reference: " + record.AddressBookCrypto.description;
          }
        }
      }
      logs += "\n\nLast login country: " + this.isNull(record.LastLoginCountry) +
        "\nLast login IP: " + this.isNull(record.LastLoginIP) +
        "\n\n" + record.Logs;

      return logs;
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.$store.commit(STOP_LOADING);
    window.addEventListener("keydown", this.handleSlashKey);
    const hash = this.$route.hash;
    if (hash) {
      const [h] = hash.split("?");
      this.selectedTab(h.replace("#", ""));
    } else {
      let id = null;
      if (this.$route.params.id) {
        id = this.$route.params.id;
        this.$router.replace({
          ...this.$router.currentRoute,
          params: {},
        });
        this.searchKYCUser(1, true, id);
      }
      if (!id) {
        this.getPendingKYC();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div v-if="meta && meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-condensed table-striped mb-0">
        <thead>
          <tr>
            <th></th>
            <th class="pointer" @click="sort('ID')">
              ID
              <span v-if="sortKey === 'ID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th>Account Type</th>
            <th>Owner/Shop</th>
            <th>Email</th>
            <th class="pointer" @click="sort('RegisteredOn')">
              Registered On
              <span v-if="sortKey === 'RegisteredOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('LastLoginOn')">
              Last Login
              <span v-if="sortKey === 'LastLoginOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th>Status</th>
            <th v-if="isShowPayoutSchema" class="text-right" style="width: 210px;min-width: 210px;">Payout Schema
            </th>
            <th style="width: 260px;min-width: 260px;"></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="data.length > 0">
            <template v-for="(record, index) in data">
              <tr :key="index">
                <td>
                  <span class="flex pointer text-success" @click="toggleRow(index)">
                    <svg v-if="expandedRowIndex !== index + 1" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <svg v-if="expandedRowIndex === index + 1" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"></path>
                    </svg>
                  </span>
                </td>
                <td>
                  <router-link :to="{ name: 'user-detail', params: { id: record.ID } }" tag="a">
                    {{ record.ID }}
                  </router-link>
                </td>
                <td>{{ record.AccountType }}</td>
                <td>{{ '(' + record.OwnerNickName + ') ' + record.ShopTitle }}</td>
                <td>{{ record.Email }}</td>
                <td class="no-wrap">{{ record.RegisteredOn }}</td>
                <td class="no-wrap">{{ record.LastLoginOn }}</td>
                <td v-html="formatStatus(record)"></td>
                <td v-if="isShowPayoutSchema" class="text-right">
                  <span class="mr-8">{{ payoutSchemaToSlug(record.PayoutSchema) }}</span>
                  <button v-tooltip="{ content: 'Reject payout', trigger: 'click hover focus' }"
                    class="btn btn-warning btn-flat btn-sm" @click="reject(record, index, listingType)">Reject
                  </button>
                </td>
                <td>
                  <button v-if="listingType === 'kyc' || listingType === 'all'"
                    class="btn btn-success btn-flat btn-sm mr-8" @click="verify(record, index)">Verify
                  </button>
                  <button v-if="isActionUnverifyAvailable(record)" class="btn btn-warning btn-flat btn-sm mr-8"
                    @click="unverify(record, index)">Unverify
                  </button>
                  <button v-if="isActionDisabledAvailable(record)" class="btn btn-danger btn-flat btn-sm mr-8"
                    @click="disable(record, index)">Disable
                  </button>
                  <button v-if="isActionEnableAvailable(record)" class="btn btn-success btn-flat btn-sm mr-8"
                    @click="enable(record, index)">
                    {{ actionEnableLabel(record) }}
                  </button>
                  <button v-if="record.Files !== 'NA'"
                    v-tooltip="{ content: 'Obtain Images', trigger: 'click hover focus' }"
                    class="btn btn-flat btn-info btn-icon btn-sm" @click="loadImages(record, index)">
                    <svg v-if="loader !== 'images_' + index" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <i v-if="loader === 'images_' + index" class="fa fa-circle-o-notch fa-spin"></i>
                  </button>
                </td>
              </tr>
              <template v-if="expandedRowIndex === (index + 1)">
                <tr :key="'detail_' + index">
                  <td :colspan="isShowPayoutSchema ? (listingType === 'all' ? 10 : 8) : (listingType === 'all' ? 9 : 7)"
                    class="px-15">
                    <div class="row">
                      <div class="col-md-6">
                        <table class="table table-condensed table-striped mb-0">
                          <tbody>
                            <tr>
                              <th colspan="2">Beneficiary Personal Information</th>
                            </tr>
                            <tr>
                              <td>Beneficiary Full Name</td>
                              <td>{{ record.PayoutFullName }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary First Name</td>
                              <td>{{ record.PayoutFirstName }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Last Name</td>
                              <td>{{ record.PayoutLastName }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Birth Date</td>
                              <td>
                                <span v-if="isEditableBirthDate === 0 || record.FinalVerification" class="pointer"
                                  @click="launchEditBirthDateForm(record, index)">
                                  {{ record.PayoutBirthDate ? record.PayoutBirthDate : 'NA' }}</span>
                                <div v-if="isEditableBirthDate === index + 1 && !record.FinalVerification" class="row">
                                  <div class="col-md-4">
                                    <ValidationObserver v-slot="{ handleSubmit }">
                                      <form @submit.prevent="handleSubmit(updateBirthDate(record, index))">
                                        <div class="flex">
                                          <ValidationProvider v-slot="{ errors }" name="birth date" rules="required"
                                            tag="div">
                                            <date-picker v-model="formBirthDate.BirthDate" :editable="false"
                                              format="YYYY-MM-DD" value-type="format"></date-picker>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                          </ValidationProvider>
                                          <button class="btn btn-primary btn-flat btn-square ml-8" type="submit">
                                            <svg v-if="loader !== 'updateBirthDate'" class="w-24 h-24" fill="none"
                                              stroke="currentColor" viewBox="0 0 24 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5 13l4 4L19 7" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"></path>
                                            </svg>
                                            <i v-if="loader === 'updateBirthDate'"
                                              class="fa fa-circle-o-notch fa-spin"></i>
                                          </button>
                                          <button class="btn btn-square btn-danger btn-flat ml-8" type="button"
                                            @click="isEditableBirthDate = 0">
                                            <svg class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round"
                                                stroke-linejoin="round" stroke-width="2"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </form>
                                    </ValidationObserver>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Beneficiary PEP</td>
                              <td>{{ record.PEP ? 'Yes' : 'No' }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Address</td>
                              <td>{{ record.PayoutAddress }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Postal Code</td>
                              <td>{{ record.PayoutPostalCode }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary City</td>
                              <td>{{ record.PayoutCity }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary State</td>
                              <td>{{ record.PayoutState }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Country</td>
                              <td>{{ record.PayoutCountry }}</td>
                            </tr>
                            <tr>
                              <th colspan="2">Beneficiary Identity Information</th>
                            </tr>
                            <tr>
                              <td>Beneficiary Identity Type</td>
                              <td>{{ record.PayoutIdentityType }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Identity Card Number</td>
                              <td>{{ record.PayoutIdentityCardNr }}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary Identity Issue Country</td>
                              <td>{{ record.PayoutIdentityCountry }}</td>
                            </tr>
                            <tr>
                              <th colspan="2">Payout Information</th>
                            </tr>
                            <tr>
                              <td>Payout Schema</td>
                              <td>{{ record.PayoutSchema }}</td>
                            </tr>
                            <template v-if="getPayoutSchemaType(record.PayoutSchema) === 'bank'">
                              <tr>
                                <td>Bank Account Name</td>
                                <td>{{ record.PayoutName }}</td>
                              </tr>
                              <tr>
                                <td>IBAN/Account Number</td>
                                <td>{{ record.PayoutIBAN }}</td>
                              </tr>
                              <tr>
                                <td>BIC/Swift/Routing code</td>
                                <td>{{ record.PayoutBIC }}</td>
                              </tr>
                            </template>
                            <template v-if="getPayoutSchemaType(record.PayoutSchema) === 'payeer'">
                              <tr>
                                <td>Paypal/Payeer</td>
                                <td>{{ record.PayoutTo }}</td>
                              </tr>
                            </template>
                            <template v-if="getPayoutSchemaType(record.PayoutSchema) === 'address-book-bank'
      && record.AddressBookBank && record.AddressBookBank !== 'NA'">
                              <tr>
                                <td>Currency</td>
                                <td>{{ record.AddressBookBank.currency }}</td>
                              </tr>
                              <tr>
                                <td>Account Holder Name</td>
                                <td>{{ record.AddressBookBank.accountHolderName }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.accountType">
                                <td>Account Type</td>
                                <td>{{ record.AddressBookBank.accountType.toLowerCase() }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.IBAN">
                                <td>IBAN</td>
                                <td>{{ record.AddressBookBank.IBAN }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.accountNumber">
                                <td>Account Number</td>
                                <td>{{ record.AddressBookBank.accountNumber }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.taxId">
                                <td>Tax Id: CUIL / CUIT</td>
                                <td>{{ record.AddressBookBank.taxId }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.bsbCode">
                                <td>BSC Code</td>
                                <td>{{ record.AddressBookBank.bsbCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.bankCode">
                                <td>Bank Code</td>
                                <td>{{ record.AddressBookBank.bankCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.branchCode">
                                <td>Branch Code</td>
                                <td>{{ record.AddressBookBank.branchCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.cpf">
                                <td>Recipient tax ID/CPF</td>
                                <td>{{ record.AddressBookBank.cpf }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.phoneNumber">
                                <td>Account Number</td>
                                <td>{{ record.AddressBookBank.phoneNumber }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.institutionNumber">
                                <td>Institution Number</td>
                                <td>{{ record.AddressBookBank.institutionNumber }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.transitCode">
                                <td>Transit Number</td>
                                <td>{{ record.AddressBookBank.transitCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.rut">
                                <td>Beneficiary's RUT number (Rol Único Tributario)</td>
                                <td>{{ record.AddressBookBank.rut }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.cardNumber">
                                <td>UnionPay card number</td>
                                <td>{{ record.AddressBookBank.cardNumber }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.sortCode">
                                <td>Sort Code</td>
                                <td>{{ record.AddressBookBank.sortCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.ifscCode">
                                <td>IFSC Code</td>
                                <td>{{ record.AddressBookBank.ifscCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.clabe">
                                <td>Recipient CLABE Number</td>
                                <td>{{ record.AddressBookBank.clabe }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.swiftCode">
                                <td>Swift Code</td>
                                <td>{{ record.AddressBookBank.swiftCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.idDocumentType">
                                <td>ID Document Type</td>
                                <td>{{ record.AddressBookBank.idDocumentType }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.idDocumentNumber">
                                <td>Identification Number</td>
                                <td>{{ record.AddressBookBank.idDocumentNumber }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.russiaRegion">
                                <td>Region</td>
                                <td>{{ record.AddressBookBank.russiaRegion }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.abartn">
                                <td>Routing Number</td>
                                <td>{{ record.AddressBookBank.abartn }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.firstLine">
                                <td>Address</td>
                                <td>{{ record.AddressBookBank.firstLine }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.city">
                                <td>City</td>
                                <td>{{ record.AddressBookBank.city }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.state">
                                <td>State</td>
                                <td>{{ record.AddressBookBank.state }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.postCode">
                                <td>Postal Code</td>
                                <td>{{ record.AddressBookBank.postCode }}</td>
                              </tr>
                              <tr v-if="record.AddressBookBank.country">
                                <td>Country</td>
                                <td>{{ record.AddressBookBank.country }}</td>
                              </tr>
                            </template>
                            <template v-if="getPayoutSchemaType(record.PayoutSchema) === 'address-book-crypto'
      && record.AddressBookCrypto && record.AddressBookCrypto !== 'NA'">
                              <tr>
                                <td>Crypto Currency</td>
                                <td>{{ record.AddressBookCrypto.altCoinID }}</td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td>{{ record.AddressBookCrypto.address }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6">
                        <div v-if="record.files" class="mb-16">
                          <silent-box :gallery="generateGallery(record.files)"></silent-box>
                        </div>
                        <form>
                          <div class="form-group">
                            <textarea v-model="record.KYCLogs" aria-label="" class="form-control" rows="12"
                              style="min-width: 100%" readonly></textarea>
                          </div>
                          <div class="text-right">
                            <button class="btn btn-primary btn-flat btn-sm" type="button"
                              @click="launchUpdateLogsModal(record, index)">
                              Update KYC Log
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
          <template v-else>
            <tr>
              <td :colspan="listingType === 'all' ? 10 : 8">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="meta && meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination.vue";
import { getPayoutSchemaType, payoutSchemas, saveNotification } from "@/lib/utils";
import DatePicker from "vue2-datepicker";

export default {
  name: "KYCListing",
  components: {
    DatePicker,
    Pagination,
  },
  props: {
    data: {
      required: true,
      default: [],
    },
    listingType: {
      required: true,
      default: "kyc",
    },
    meta: {
      type: Object,
      default() {
        return {
          total: 0,
          page: 1,
          offset: 0,
          limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
        };
      },
    },
    sortKey: {
      type: String,
      default: "RegisteredOn",
    },
    order: {
      type: String,
      default: "desc",
    },
  },
  data() {
    return {
      loader: "",
      expandedRowIndex: 0,
      payoutSchemas: payoutSchemas,
      isEditableBirthDate: 0,
      formBirthDate: {
        BirthDate: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "jwtToken",
    ]),
    isShowPayoutSchema() {
      return this.listingType !== "unverified" && this.listingType !== "disabled";
    },
  },
  methods: {
    resetIndex() {
      this.expandedRowIndex = 0;
      this.isEditableBirthDate = 0;
    },
    isNull(val) {
      return val && val !== "" && val !== null ? val : "NA";
    },
    updatePage(page) {
      this.$emit("updatePage", page);
    },
    formatStatus(record) {
      const formattedStatus = (record.Disabled) ? "Disabled" : (record.FinalVerification ? "Verified" : "Unverified");
      const lblClass = (record.Disabled) ? "label-danger" : (record.FinalVerification ? "label-success" : "label-warning");
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    sort(key) {
      this.$emit("updatePage", this.meta.page, key);
    },
    toggleRow(index) {
      const i = index + 1;
      this.expandedRowIndex = this.expandedRowIndex === i ? 0 : i;
      if (this.expandedRowIndex !== 0) {
        if (typeof this.data[index].files === "undefined") {
          this.loadImages(this.data[index], index);
        }
      }
      this.isEditableBirthDate = 0;
    },
    payoutSchemaToSlug(schema) {
      const payoutSchema = this._.find(this.payoutSchemas, { title: schema });
      if (payoutSchema) {
        schema = payoutSchema.slug;
      }
      return schema;
    },
    launchEditBirthDateForm(record, index) {
      if (!record.FinalVerification) {
        this.isEditableBirthDate = index + 1;
        this.formBirthDate.BirthDate = record.PayoutBirthDate;
      } else {
        this.$toast.fire("", "Verified record update not allowed", "warning");
        saveNotification("Verified record update not allowed");
      }
    },
    updateBirthDate(record, index) {
      if (this.formBirthDate.BirthDate !== "") {
        this.loader = "updateBirthDate";
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$http.get(`${apiBaseUrl}/v2REAPI`, {
          params: {
            Call: "UpdateBirthdate",
            MerchantID: record.ID,
            APIKey: record.APIKey,
            Date: this.formBirthDate.BirthDate,
            output: "json",
          },
        }).then(response => {
          this.loader = "";
          if (response.data.status_code === 200) {
            this.isEditableBirthDate = 0;
            record.PayoutBirthDate = this.formBirthDate.BirthDate;
            this.updateLogs(record, index, response.data.message);
          }
        }).catch(error => {
          this.loader = "";
          console.log(error);
        });
      }
    },
    payoutSchemaReminder(record, index) {
      this.$swal.fire({
        title: "Payout Schema Reminder",
        text: `Are you sure, want to reject ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Reject",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "AccountInfoReminder",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.ID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const msgType = (response.data.status_code === 200) ? "success" : "error";
            if (msgType === "success") {
              this.updateLogs(record, index, "Payout Schema Rejected");
              saveNotification("Payout Schema Rejected");
            }
            this.$toast.fire("", response.data.message, msgType);
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    reject(record, index) {
      this.$swal.fire({
        title: "Reject",
        text: `Are you sure, want to reject payout for ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Reject",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "AccountInfoReminder",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.ID,
            PayoutSettingsChangeOnly: "",
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const msgType = (response.data.status_code === 200) ? "success" : "error";
            if (msgType === "success") {
              this.updateLogs(record, index, "Payout Schema Rejected");
              saveNotification("Payout Schema Rejected");
            }
            this.$toast.fire("", response.data.message, msgType);
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    verify(record, index) {
      this.$swal.fire({
        title: "Verify",
        text: `Are you sure, want to verify ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Verify",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "AccountVerified",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.ID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const type = (response.data.status_code === 200) ? "success" : "error";
            if (type === "success") {
              this.updateLogs(record, index, "Verified");
            }
            this.$toast.fire("", response.data.message, type);
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    unverify(record, index) {
      this.$swal.fire({
        title: "Unverify",
        text: `Are you sure, want to unverify ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Unverify",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "AccountInfoReminder",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.ID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const msgType = (response.data.status_code === 200) ? "success" : "error";
            if (msgType === "success") {
              if (msgType === "success") {
                this.updateLogs(record, index, "Unverified");
              }
            }
            this.$toast.fire("", response.data.message, msgType);
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    disable(record, index) {
      this.$swal.fire({
        title: "Disable",
        text: `Are you sure, want to disable ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd4b39",
        confirmButtonText: "Disable",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "KYCDisableAccount",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.ID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const msgType = (response.data.status_code === 200) ? "success" : "error";
            if (msgType === "success") {
              this.updateLogs(record, index, "Disabled");
            }
            this.$toast.fire("", response.data.message, msgType);
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    enable(record, index) {
      this.$swal.fire({
        title: "Enable",
        text: `Are you sure, want to enable ${record.ShopTitle}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Enable",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "KYCEnableAccount",
            MerchantID: this.currentUser.ID,
            APIKey: "_",
            targetMerchantID: record.ID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            const type = (response.data.status_code === 200) ? "success" : "error";
            if (type === "success") {
              if (this.listingType === "disabled") {
                this.updateLogs(record, index, "Enabled");
              }
            }
            this.$toast.fire("", response.data.message, type);
          }).catch(error => {
            console.log(error); // TODO: show toast message
            this.$swal.close();
          });
        }
      });
    },
    loadImages(record, index) {
      this.loader = "images_" + index;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        POSDATA: "",
        Call: "SHOW",
        KeyData: "FILES_" + record.ID,
        MerchantID: record.ID,
        APIKey: record.APIKey,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/MarketAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.data !== "nothing found") {
          let files = {
            passport: "",
            avatar: "",
          };
          if (typeof response.data.avatar !== "undefined") {
            files = response.data;
            if (files.avatar && files.avatar.startsWith("https://cointopay.com")) {
              files.avatar = files.avatar.replace("https://cointopay.com", "https://app.cointopay.com");
            }
            if (files.passport && files.passport.startsWith("https://cointopay.com")) {
              files.passport = files.passport.replace("https://cointopay.com", "https://app.cointopay.com");
            }
          }
          record.files = files;
          this.data.splice(index, 1, record);
          this.expandedRowIndex = (index + 1);
        }
      }).catch(error => {
        this.loader = "";
        console.log(error.response);
      });
    },
    generateGallery(files) {
      const images = [];
      if (files.passport !== "") {
        images.push({
          src: files.passport,
          thumbnailWidth: "220px",
          thumbnailHeight: "160px",
        });
      }
      if (files.avatar !== "") {
        images.push({
          src: files.avatar,
          thumbnailWidth: "220px",
          thumbnailHeight: "160px",
        });
      }
      return images;
    },
    updateLogs(record, index, status = "") {
      this.loader = "update_logs_" + index;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const encodeForm = data => {
        return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
      };
      const payload = {
        Call: "KYCLOG",
        MerchantID: record.ID,
        APIKey: record.APIKey,
        output: "json",
      };
      if (status === "") {
        Object.assign(payload, {
          kyclog: record.KYCLogs,
        });
      } else {
        const action = this.tz().format("DD/MM/YYYY HH:mm:ss") + " " + this.currentUser.Username + ": status: " + status + "\n";
        Object.assign(payload, {
          kyclog: action + record.Logs,
        });
      }
      this.$http.post(`${apiBaseUrl}/v2REAPI`, encodeForm(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        const msgType = (response.data.status_code === 200) ? "success" : "error";
        if (msgType === "success") {
          if (status === "" || status.toLowerCase() === "birthdate updated") {
            record.Logs = record.KYCLogs;
            record.KYCLogs = this.generateKYCLogs(record);
            this.data.splice(index, 1, record);
            const message = status === "" ? response.data.message : status;
            this.$toast.fire("", message, msgType);
          } else {
            if (this.listingType !== "unverified" || (this.listingType === "unverified" && status !== "Unverified")) {
              this.data.splice(index, 1);
              const meta = { ...this.meta };
              this.$nextTick(function () {
                this.meta = Object.assign({}, this.meta, {
                  limit: meta.limit - 1,
                  total: meta.total - 1,
                });
              });
              this.resetIndex();
            }
          }
          if (status === "Enabled") {
            this.$emit("updateTitle", record);
          } else {
            this.$emit("updateTitle", null);
          }
        }
        this.loader = "";
      }).catch(error => {
        console.log(error);
        this.loader = "";
      });
    },
    getPayoutSchemaType(payoutSchema) {
      return getPayoutSchemaType(payoutSchema);
    },
    generateKYCLogs(record) {
      // eslint-disable-next-line quotes
      let logs = '##KYC LOG HISTORY##\n' +
        this.tz().format("YYYY-MM-DD HH:mm:ss") +
        "\n\nSubmitted: " + (record.Verified ? 1 : 0) +
        "\nVerified: " + (record.FinalVerification ? 1 : 0) +
        "\nAccount Disabled: " + (record.Disabled ? 1 : 0) +
        "\nAccount Active: " + (record.Active ? 1 : 0) +
        "\nConfirmedEmail: " + (record.ConfirmedEmail ? 1 : 0) +
        "\n2FA ON: " + (record.Google2FAEnabled ? 1 : 0) +
        "\nEmail: " + record.Email +
        "\nPayeer/Paypal: " + this.isNull(record.PayoutTo) +
        "\nRegisteredOn: " + record.RegisteredOn +
        "\nLastLoginOn: " + this.isNull(record.LastLoginOn) +
        "\nAccountUpdatedOn: " + this.isNull(record.LastUpdatedOn) +
        "\n\nAccount: " + record.AccountType.toUpperCase();
      // Business
      if (record.AccountType.toLowerCase() === "business") {
        logs += "\n\nBusiness Name: " + this.isNull(record.PayoutBankName) +
          "\nBusiness Address: " + this.isNull(record.PayoutBankAddress) +
          "\nBusiness PostalCode: " + this.isNull(record.PayoutBankPostalCode) +
          "\nBusiness City: " + this.isNull(record.PayoutBankCity) +
          "\nBusiness State: " + this.isNull(record.PayoutBankState) +
          "\nBusiness Country: " + this.isNull(record.PayoutBankCountry);
      }
      // Personal
      logs += "\n\nBeneficiary FullName: " + this.isNull(record.PayoutFullName) +
        "\nBeneficiary First Name: " + this.isNull(record.PayoutFirstName) +
        "\nBeneficiary Last Name: " + this.isNull(record.PayoutLastName) +
        "\nBeneficiary BirthDate: " + this.isNull(record.PayoutBirthDate) +
        "\nBeneficiary PEP (Exposed): " + (record.PEP ? 1 : 0) +
        "\nBeneficiary Address: " + this.isNull(record.PayoutAddress) +
        "\nBeneficiary PostalCode: " + this.isNull(record.PayoutPostalCode) +
        "\nBeneficiary City: " + this.isNull(record.PayoutCity) +
        "\nBeneficiary State: " + this.isNull(record.PayoutState) +
        "\nBeneficiary Country: " + this.isNull(record.PayoutCountry);
      // Payout Information
      const payoutSchema = this.getPayoutSchemaType(record.PayoutSchema);
      if (payoutSchema !== "") {
        logs += "\n\nPayout Information:" +
          "\nPayout schema: " + record.PayoutSchema;
        if (payoutSchema === "bank") {
          logs += "\nName Bank account: " + this.isNull(record.PayoutName) +
            "\nIBAN/Account number: " + this.isNull(record.PayoutIBAN) +
            "\nBIC/Swift/Routing code: " + this.isNull(record.PayoutBIC);
        } else if (payoutSchema === "payeer") {
          logs += "\nPayal/Payeer: " + this.isNull(record.PayoutTo);
        } else if (payoutSchema === "address-book-bank") {
          if (record.AddressBookBank !== "NA") {
            logs += "\nCurrency: " + record.AddressBookBank.currency +
              "\nAccount holder name: " + record.AddressBookBank.accountHolderName;
            if (record.AddressBookBank.accountType) {
              logs += "\nAccount type: " + record.AddressBookBank.accountType.toLowerCase();
            }
            if (record.AddressBookBank.IBAN) {
              logs += "\nIBAN: " + record.AddressBookBank.IBAN;
            }
            if (record.AddressBookBank.accountNumber) {
              logs += "\nAccount number: " + record.AddressBookBank.accountNumber;
            }
            if (record.AddressBookBank.taxId) {
              logs += "\nTax Id: CUIL / CUIT: " + record.AddressBookBank.taxId;
            }
            if (record.AddressBookBank.bsbCode) {
              logs += "\nBSB code: " + record.AddressBookBank.bsbCode;
            }
            if (record.AddressBookBank.bankCode) {
              logs += "\nBank code: " + record.AddressBookBank.bankCode;
            }
            if (record.AddressBookBank.branchCode) {
              logs += "\nBranch code: " + record.AddressBookBank.branchCode;
            }
            if (record.AddressBookBank.cpf) {
              logs += "\nRecipient tax ID/CPF: " + record.AddressBookBank.cpf;
            }
            if (record.AddressBookBank.phoneNumber) {
              logs += "\nPhone number: " + record.AddressBookBank.phoneNumber;
            }
            if (record.AddressBookBank.institutionNumber) {
              logs += "\nInstitution number: " + record.AddressBookBank.institutionNumber;
            }
            if (record.AddressBookBank.transitCode) {
              logs += "\nTransit number: " + record.AddressBookBank.transitCode;
            }
            if (record.AddressBookBank.rut) {
              logs += "\nBeneficiary's RUT number (Rol Único Tributario): " + record.AddressBookBank.rut;
            }
            if (record.AddressBookBank.cardNumber) {
              logs += "\nUnionPay card number: " + record.AddressBookBank.cardNumber;
            }
            if (record.AddressBookBank.sortCode) {
              logs += "\nSort code: " + record.AddressBookBank.sortCode;
            }
            if (record.AddressBookBank.ifscCode) {
              logs += "\nIFSC code: " + record.AddressBookBank.ifscCode;
            }
            if (record.AddressBookBank.clabe) {
              logs += "\nRecipient CLABE number: " + record.AddressBookBank.clabe;
            }
            if (record.AddressBookBank.swiftCode) {
              logs += "\nSwift code: " + record.AddressBookBank.swiftCode;
            }
            if (record.AddressBookBank.idDocumentType) {
              logs += "\nID document type: " + record.AddressBookBank.idDocumentType;
            }
            if (record.AddressBookBank.idDocumentNumber) {
              logs += "\nIdentification number: " + record.AddressBookBank.idDocumentNumber;
            }
            if (record.AddressBookBank.russiaRegion) {
              logs += "\nRegion: " + record.AddressBookBank.russiaRegion;
            }
            if (record.AddressBookBank.abartn) {
              logs += "\nRouting number: " + record.AddressBookBank.abartn;
            }
            if (record.AddressBookBank.firstLine) {
              logs += "\nAddress: " + record.AddressBookBank.firstLine;
            }
            if (record.AddressBookBank.city) {
              logs += "\nCity: " + record.AddressBookBank.city;
            }
            if (record.AddressBookBank.state) {
              logs += "\nState: " + record.AddressBookBank.state;
            }
            if (record.AddressBookBank.postCode) {
              logs += "\nPostal Code: " + record.AddressBookBank.postCode;
            }
            if (record.AddressBookBank.country) {
              logs += "\nCountry: " + record.AddressBookBank.country;
            }
          }
        } else if (payoutSchema === "address-book-crypto") {
          if (record.AddressBookCrypto !== "NA") {
            logs += "\nCrypto Currency: " + record.AddressBookCrypto.altCoinID +
              "\nAddress: " + record.AddressBookCrypto.address +
              "\nCustomer Reference: " + record.AddressBookCrypto.description;
          }
        }
      }
      logs += "\n\nLast login country: " + this.isNull(record.LastLoginCountry) +
        "\nLast login IP: " + this.isNull(record.LastLoginIP) +
        "\n\n" + record.Logs;

      return logs;
    },
    launchUpdateLogsModal(record, index) {
      this.$bus.$emit("logs-modal", {
        title: "Update Logs",
        data: record,
        confirm: (logs) => {
          record.Logs = logs;
          record.KYCLogs = this.generateKYCLogs(record);
          this.data.splice(index, 1, record);
        },
      });
    },
    isActionUnverifyAvailable(record) {
      if (this.listingType === "all") {
        return !record.Disabled;
      } else {
        return this.listingType !== "disabled";
      }
    },
    isActionDisabledAvailable(record) {
      if (this.listingType === "all") {
        return !record.Disabled;
      } else {
        return this.listingType !== "disabled";
      }
    },
    isActionEnableAvailable(record) {
      if (this.listingType === "all") {
        return record.Disabled || !record.Verified;
      } else {
        return this.listingType === "disabled" || this.listingType === "";
      }
    },
    actionEnableLabel(record) {
      if (this.listingType === "all") {
        return record.Disabled ? "Enable" : "Submit";
      } else {
        return this.listingType === "unverified" ? "Submit" : "Enable";
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped></style>
